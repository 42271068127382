.btn_wrapper {
  margin-top: 20px;
  // padding-top: 10px;
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.btn_wrapper2 {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}
