.dashboard {
  .row1 {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;

    .item {
      background-color: var(--primary400);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      flex: 1;
      margin: 0 10px;

      .icon {
        color: var(--greenAccent600);
        font-size: 26px;
      }
    }
  }

  .row2 {
    display: flex;
    position: relative;
    margin-top: 30px;
    height: 350px;
    // align-items: flex-start;

    .chart {
      display: flex;
      width: 60%;
      margin-right: 10px;
      flex-direction: column;
      // flex: 60%;
      // flex-grow: 0;
      // height: 100%;
      // flex-shrink: 0;

      .charContainer {
        position: relative;
        // margin: auto;
        height: 100%;
        width: 100%;
      }

      .title {
        font-weight: 600;
        font-size: 16px;
        color: var(--grey100);
        margin: 0;
      }

      .amount {
        font-weight: 600;
        font-size: 16px;
        color: var(--greenAccent500);
        margin: 0;
      }
    }

    .topCustomer {
      width: 40%;
      margin-left: 10px;

      .container {
        overflow: auto;
        width: 100%;
        height: 100%;
      }

      .container::-webkit-scrollbar {
        width: 4px;
      }

      .transactions {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        background-color: var(--primary400);

        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px 0;
          flex-wrap: wrap;

          border-bottom-style: solid;
          border-bottom-width: 1px;
          border-color: var(--grey200);

          .name {
            font-weight: bold;
            font-size: 16px;
            color: var(--greenAccent500);
            margin: 0;
          }
          .text {
            margin: 0;
            color: var(--grey100);
          }

          .price {
            padding: 5px 10px;
            border-radius: 4px;
            background-color: var(--greenAccent500);
          }
        }
      }
    }
  }
}
