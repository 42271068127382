.product {
  .info {
    background-color: var(--primary400);
    border-radius: 5px;
    padding: 20px;
    display: inline-flex;
    position: relative;

    .btn_edit {
      position: absolute;
      right: 0;
      top: 0;

      border-radius: 0;
      border-bottom-left-radius: 8px;
    }
  }

  .tables {
    width: 100%;

    .wrapper {
      .title {
        margin-top: 20px;
      }

      .table {
        margin-top: 10px;
      }
    }
  }
}
