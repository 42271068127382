.tables {
    display: flex;
    flex: 100%;
    margin-top: 20px;
    gap: 20px;
    .table1 {
      width: calc(54% - 10px);
      .item {
        color: rgb(19, 184, 19);
      }
    }
    .table2 {
      width: calc(46% - 10px);
      .item {
        color: rgb(156, 21, 21);
      }
    }
  
    .item {
      display: flex;
      padding-bottom: 10px;
      font-size: 20px;
      font-weight: 600;
      .label {
        margin-right: 10px;
      }
    }
  }
  