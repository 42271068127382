.menu {
  .filters {
    display: flex;
    gap: 10px;

    .item {
      position: relative;
      .label {
        position: absolute;
        top: -8px;
        left: 4px;
        background-color: var(--greenAccent500);
        border-radius: 4px;
        padding: 0 4px;

        font-size: 10px;
        margin: 0;
        z-index: 1;
      }
      .input {
        width: 200px;
      }
    }
  }

  .btn {
    margin-top: 10px;
  }
}
