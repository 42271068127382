:root {
  --grey100: #141414;
  --grey200: #292929;
  --grey300: #3d3d3d;
  --grey400: #525252;
  --grey500: #666666;
  --grey600: #858585;
  --grey700: #a3a3a3;
  --grey800: #c2c2c2;
  --grey900: #e0e0e0;

  --primary100: #040509;
  --primary200: #080b12;
  --primary300: #0c101b;
  --primary400: #f2f0f0;
  --primary500: #141b2d;
  --primary600: #1f2a40;
  --primary700: #727681;
  --primary800: #a1a4ab;
  --primary900: #d0d1d5;

  --greenAccent100: #0f2922;
  --greenAccent200: #1e5245;
  --greenAccent300: #2e7c67;
  --greenAccent400: #3da58a;
  --greenAccent500: #4cceac;
  --greenAccent600: #70d8bd;
  --greenAccent700: #94e2cd;
  --greenAccent800: #b7ebde;
  --greenAccent900: #dbf5ee;

  --redAccent100: #2c100f;
  --redAccent200: #58201e;
  --redAccent300: #832f2c;
  --redAccent400: #af3f3b;
  --redAccent500: #db4f4a;
  --redAccent600: #e2726e;
  --redAccent700: #e99592;
  --redAccent800: #f1b9b7;
  --redAccent900: #f8dcdb;

  --blueAccent100: #151632;
  --blueAccent200: #2a2d64;
  --blueAccent300: #3e4396;
  --blueAccent400: #535ac8;
  --blueAccent500: #6870fa;
  --blueAccent600: #868dfb;
  --blueAccent700: #a4a9fc;
  --blueAccent800: #c3c6fd;
  --blueAccent900: #e1e2fe;

  --bg: #fcfcfc;
  --boxShadow: rgba(34, 60, 80, 0.2);
}
