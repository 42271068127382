.field {
  width: 300px;

  .btn {
    margin-top: 20px;
  }

  .item {
    margin-top: 10px;

    .wrapper {
      position: relative;
      display: inline;

      .icon {
        font-size: 28px;
        position: absolute;
        top: -6px;
        right: -34px;
        cursor: pointer;
      }
    }
  }
}
