.product {
  .btn_list {
    display: flex;
    margin-top: 50px;
    gap: 10px;
  }

  .info {
    background-color: var(--primary400);
    border-radius: 5px;
    padding: 20px;
    display: inline-flex;
    position: relative;

    .btn_edit {
      position: absolute;
      right: 0;
      top: 0;

      border-radius: 0;
      border-bottom-left-radius: 8px;
    }

    .items {
      margin-top: 20px;
      flex-direction: column;
      width: 700px;

      .btn {
        margin-top: 20px;
      }
    }
  }

  .table_wrapper {
    margin-top: 20px;
    min-height: 500px;

    .title {
      font-size: 20px;
      padding: 0px 0 10px 0;
    }
  }
}
