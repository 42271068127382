.catalog {
  .items {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;

    .item {
      // flex: 10%;
      width: 200px;
      height: 200px;
      background-color: var(--primary400);
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      text-decoration: none;
      &:visited {
        color: inherit;
      }

      .image {
        padding: 6px 6px 0 6px;
        overflow: hidden;
        flex: 70%;

        img {
          border-radius: 4px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;

        flex: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
