.wrapper {
  //   --sh_color: rgba(77, 51, 170, 0.8);
  --sh_color: rgb(128, 128, 128);
  --sh_color2: rgba(255, 255, 255, 1);
  --sh_color3: rgba(255, 255, 255, 1);
  height: 100%;
  width: 100%;

  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-weight: 700;
    font-size: 100px;
    z-index: 10;
    color: rgba(253, 253, 253, 0.4);
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 0%;
  }

  .form {
    position: relative;
    // background-color: red;
    z-index: 10;
    width: 320px;

    .input {
      border: 2px solid gray;
      border-radius: 6px;
      padding: 12px 16px;
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      transition: border 0.2s linear;
      background-color: rgba(255, 255, 255, 0.1);
      color: rgb(230, 221, 221);
      margin-top: 10px;
      &:focus-visible {
        outline: none;
        border-color: white;
      }

      //   &:last-child {
      //     margin-top: 12px;
      //   }
    }

    .error {
      padding: 5px 10px;
      font-size: 14px;
      font-weight: 700;
      color: red;
    }

    .button {
      margin-top: 12px;
      width: 100%;
      border-radius: 6px;
      //   border-color: none;
      border: none;
      padding: 12px 16px;
      font-weight: 500;
      font-size: rem(16);
      line-height: 150%;
      background-color: rgba(142, 142, 238, 0.527);
      color: white;
      font-weight: 600;

      &:hover {
        opacity: 0.8;
        transform: scale(1.01);
      }
    }
  }

  .video_wrapper {
    position: absolute;
    // background-color: aqua;
    min-width: 100%;
    min-height: 100%;

    .video {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: top;
    }
  }
}
