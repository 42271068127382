.dark {
  --grey100: #e0e0e0;
  --grey200: #c2c2c2;
  --grey300: #a3a3a3;
  --grey400: #858585;
  --grey500: #666666;
  --grey600: #525252;
  --grey700: #3d3d3d;
  --grey800: #292929;
  --grey900: #141414;

  --primary100: #d0d1d5;
  --primary200: #a1a4ab;
  --primary300: #727681;
  --primary400: #1f2a40;
  --primary500: #141b2d;
  --primary600: #101624;
  --primary700: #0c101b;
  --primary800: #080b12;
  --primary900: #040509;

  --greenAccent100: #dbf5ee;
  --greenAccent200: #b7ebde;
  --greenAccent300: #94e2cd;
  --greenAccent400: #70d8bd;
  --greenAccent500: #4cceac;
  --greenAccent600: #3da58a;
  --greenAccent700: #2e7c67;
  --greenAccent800: #1e5245;
  --greenAccent900: #0f2922;

  --redAccent100: #f8dcdb;
  --redAccent200: #f1b9b7;
  --redAccent300: #e99592;
  --redAccent400: #e2726e;
  --redAccent500: #db4f4a;
  --redAccent600: #af3f3b;
  --redAccent700: #832f2c;
  --redAccent800: #58201e;
  --redAccent900: #2c100f;

  --blueAccent100: #e1e2fe;
  --blueAccent200: #c3c6fd;
  --blueAccent300: #a4a9fc;
  --blueAccent400: #868dfb;
  --blueAccent500: #6870fa;
  --blueAccent600: #535ac8;
  --blueAccent700: #3e4396;
  --blueAccent800: #2a2d64;
  --blueAccent900: #151632;

  --bg: #141b2d;
  --boxShadow: rgba(230, 230, 230, 0.2);
}
