.cell{   
    padding: 1px 10px;
    &:nth-child(1){
        width: 40px;
    }

    &:nth-child(2){
        width: 140px;
    }

    &:nth-child(4){
        width: 80px;
    }
}

