.wrapper {
  margin-top: 10px;
  display: flex;
  gap: 30px;

  .item{
    margin-top: 10px;
    display: flex;
    align-items: center;

    .title{
      width: 120px;
    }

    .select {
      width: 250px;
    }
  }
}

.renderCell{
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}