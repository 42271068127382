.field {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .label {
    font-size: 20px;
    & span {
      color: red;
    }
  }

  .input {
    width: 300px;
  }

  .field_btn {
    display: flex;

    .input {
      width: 250px;
      margin-right: 10px;
    }

    .btn_plus {
      border-radius: 4px;
      background-color: var(--greenAccent500);
      border: none;
      cursor: pointer;
      // text
      font-family: inherit;
      text-transform: uppercase;
      text-decoration: none;
      color: var(--grey100);
      font-size: 20px;
      padding: 0;
      width: 40px;
      line-height: 1;
      font-weight: bold;
    }
    .btn_cancel {
      margin-left: 10px;
      background-color: var(--redAccent500);
    }
  }
}
