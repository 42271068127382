.table{
    margin-top: 20px;

    .no_product{
        padding: 30px 0;
        text-align: center;
    }

    .title{
        display: flex;
        padding: 6px;
        padding-right: 20px;
        margin-right: 8px;
    }

    .wrapper{
        overflow: auto;
        max-height: 200px;

        &::-webkit-scrollbar {width: 5px;}
        &::-webkit-scrollbar-track {background: #e0e0e0;}
        &::-webkit-scrollbar-thumb {background: #888;}
        &::-webkit-scrollbar-thumb:hover {background: #555;}
    }

    .row{
        cursor: pointer;
        user-select: none; /* Стандартное свойство */
        display: flex;
        padding: 6px;

        padding-right: 20px;
        margin-right: 8px;
        
        border-radius: 8px;
        // padding: 0 ;
        // width: 100%;
        // background-color: aquamarine;
        &:hover{
            background-color: var(--greenAccent600);
        }
    }


}