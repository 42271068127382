@import "../BoxUI/BoxUI.module.scss";

.container {
  // display: inline-flex;
  // flex-direction: column;
  // background-color: var(--primary400);
  // padding: 20px;
  // border-radius: 4px;
  margin-top: 20px;
  width: 700px;
}
