.wrapper {
  .info {
    display: inline-flex;
    position: relative;

    .btn_edit {
      position: absolute;
      right: 0;
      top: 0;

      border-radius: 0;
      border-bottom-left-radius: 8px;
    }
  }
}
