.btn {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px 20px 20px;

  .link_step {
    cursor: pointer;
    border: solid 1px var(--greenAccent400);
    color: var(--greenAccent400);
    display: inline;
    padding: 15px;
    border-radius: 50%;
    position: relative;

    .arrow {
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: auto;
  }
}
