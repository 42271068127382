.product {
  .info {
    background-color: var(--primary400);
    border-radius: 5px;
    padding: 20px;
    // display: inline-flex;
    position: relative;

    .customer {
      font-size: 24px;
      color: var(--greenAccent400);
      text-decoration: none;
      border-bottom: 2px dashed;
    }

    .btn_edit {
      position: absolute;
      right: 0;
      top: 0;

      border-radius: 0;
      border-bottom-left-radius: 8px;
    }

    .items {
      margin-top: 20px;
      flex-direction: column;
      width: 700px;

      .btn {
        margin-top: 20px;
      }
    }
  }

  .tables {
    width: 100%;
    // overflow: hidden;
    // display: flex;
    // gap: 20px;
    // flex-wrap: wrap;
    // margin: 0 -10px;

    .wrapper {
      .title {
        margin-top: 20px;
      }

      .table {
        margin-top: 10px;
      }
      // margin: 0 10px;
      // flex: 0 0 calc(50% - 20px);
      // @media (max-width: 1400px) {
      //   flex: 0 0 calc(100% - 39px);
      // }
    }
  }
}
