.menu_table {
  background-color: #2a2d64;
  background-color: var(--primary400);
  border-radius: 5px;
  padding: 10px;

  .items {
    display: flex;
    gap: 10px;

    .select {
      width: 200px;
      // display: flex;
    }
  }

  .date {
    display: flex;
    margin-top: 15px;
    gap: 10px;
  }
}
