.container{
    display: flex;
    justify-content: center;
}

.preloader{
    height: 30px;
    width: 30px;

    border-radius:50%;
    border: 4px solid var(--greenAccent400);
    border-bottom: 4px solid  rgba(0, 0, 0, 0.0);
    animation: loader 1s infinite linear;
}

@keyframes loader {
    from{
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
    
}