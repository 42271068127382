.menu {
  .fields {
    display: flex;
    gap: 10px;
    align-items: center;

    .select {
      width: 200px;
    }

    .label {
      width: 104px;
      font-size: 14px;
    }

    .icon {
      margin-left: 16px;
      cursor: pointer;
    }
  }

  .btn {
    // margin-top: 10px;
  }
}
