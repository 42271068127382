.sidebar {
  background-color: var(--primary400);
  transition: width 0.5s ease;
  width: 60px;
  padding: 0 5px;
  overflow: hidden;
  box-shadow: 6px 0px 8px 0px rgba(34, 60, 80, 0.2);
  z-index: 100;

  &.open {
    width: 300px;
    padding: 0 20px;
    .link {
      p {
        opacity: 1;
      }
    }

    .title {
      justify-content: flex-end;
    }
  }

  .subtitle {
    border-bottom: 1px solid var(--greenAccent600);
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      display: block;
      margin-top: 18px;
      margin-bottom: 18px;
      font-size: 32px;
    }
  }

  .link {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 16px;
    color: var(--grey500);
    gap: 10px;
    padding: 10px;
    margin: 5px 0;
    text-decoration: none;

    &.active {
      background-color: var(--greenAccent500);
      border-radius: 5px;
      color: var(--grey100);
    }

    p {
      transition: opacity 0.5s ease;
      opacity: 0;
      margin: 0;
    }

    .icon {
      svg {
        font-size: 25px;
      }
    }

    &:hover {
      background-color: var(--greenAccent400);
      border-radius: 5px;
      color: var(--grey100);
    }
  }
}
