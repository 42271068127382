.items {
  display: flex;
  gap: 10px;

  .select {
    width: 200px;
    // display: flex;
  }
}

.date {
  display: flex;
  margin-top: 15px;
  gap: 10px;
  align-items: flex-end;

  .field {
    width: 130px;
  }

  .label {
    width: 104px;
    font-size: 14px;
    padding-bottom: 4px;
  }
}
