.header {
  background-color: var(--primary400);
  box-shadow: 0px 6px 8px 0px rgba(34, 60, 80, 0.2);
  // box-shadow: 0px 6px 10px 0px var(--boxShadow);
  position: sticky;
  top: 0;
  // position: fixed;
  width: 100%;
  z-index: 100;
  // left: 60px;

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;

    .icon_wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      a {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 6px;
        border-radius: 50%;
      }

      .active {
        background-color: var(--greenAccent500);

        .icon {
          // position: relative;
        }
      }
    }
  }
}

.account_wrapper {
  position: relative;
  .account {
    padding: 10px 20px;
    position: absolute;
    background-color: var(--primary400);
    border-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 8px 8px 4px rgba(34, 60, 80, 0.2);
    right: 0;
    top: 45px;
    .item {
      padding: 0 !important;
      font-size: 16px;
      text-decoration: none;
      margin-top: 5px;

      &:last-child {
        color: var(--redAccent500);
      }
      &:first-child {
        font-weight: 600;
      }
    }
  }
}
