.search_wrapper {
  position: relative;
  background-color: var(--grey900);
  border-radius: 3px;

  .input {
    padding: 0 14px;
    width: 300px;
  }

  .result_window {
    position: absolute;
    z-index: 10;

    top: 60px;
    left: 0;
    width: 130%;
    // box-shadow: 0px 6px 10px 6px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 5px 10px 2px var(--boxShadow);

    .title {
      margin-top: 10px;
      font-size: 16px;
      color: var(--grey400);
    }

    .item_search {
      & a {
        text-decoration: none;
        border-bottom: 1px solid var(--greenAccent600);
      }

      .fullname {
        font-size: 14px;
        color: var(--grey300);
      }
    }

    .nothing_found {
      font-size: 16px;
      display: flex;
      height: 60px;
      align-items: flex-end;
    }
  }
}
