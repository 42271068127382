.btn {
  margin: 0;
  padding: 6px 12px;
  border-radius: 4px;
  border-color: var(--greenAccent500);
  border-style: solid;
  border-width: 2px;

  cursor: pointer;

  // text
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.75;
  font-family: inherit;
  text-transform: uppercase;
  text-decoration: none;
  // color: var(--grey100);
  color: var(--greenAccent500);

  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  transition: 
    // background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  position: relative;

  &::after {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    content: "";
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    &::after {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  // Действие при клике
  &:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 4px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 15px var(--greenAccent500);
  }

  &:active:after {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    position: absolute;
    border-radius: 4px;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
  }

  // обнуление
  &:active {
    color: var(--greenAccent500);
  }

  &:focus-visible {
    outline: none;
  }

  &:visited {
    color: var(--greenAccent500);
  }
}

.active.btn {
  background-color: var(--greenAccent500);
  color: var(--grey100);
}
