.message {
  overflow: hidden;
  margin-bottom: 10px;
  min-height: 80px;
  width: 100%;
  box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.2);

  .title {
    margin-bottom: 4px;
    display: flex;
    justify-content: right;
    .icon {
      font-size: 16px;
      cursor: pointer;
    }
  }
}
