.items {
    // display: flex;
    gap: 10px;
  
    .select {
      width: 200px;
      // display: flex;
    }
  }
  
  .date {
    display: flex;
    margin-top: 15px;
    gap: 10px;
    align-items: center;
  
  
    .label {
      font-size: 14px;
    }
  }
  
  .balance {
    margin: 10px 0px;
    font-size: 14px;
    border: greenyellow solid 1px;
    padding: 4px 8px;
    border-radius: 5px;
    display: flex;
  
    .amount{
      margin-left: 6px;
      font-weight: 700;
    }
  }
  