.container {
  width: 100%;
  margin: 0 10px;
  display: flex;

  .column1 {
    display: flex;
    flex-direction: column;
    width: 65%;
    gap: 2px;

    .number {
      font-weight: bold;
      font-size: 18px;
      color: var(--grey100);
    }

    .text1 {
      font-size: 16px;
      color: var(--greenAccent500);
      font-weight: 700;
    }
  }

  .column2 {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pie_chart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      //   padding: 10px;
      //   margin: 10px;
    }

    .percent {
      margin-top: 10px;
      font-size: 16px;
      font-style: italic;
      color: var(--greenAccent600);
    }
  }
}
