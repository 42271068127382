@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html {
  // display: flex;
  // justify-content: center;
  /* align-items: center; */
  /* flex-direction: column; */
  // flex-grow: 1;
  // flex-shrink: 0;
}

body {
  min-width: 1024px;
  width: 100%;
}

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
}

.content {
  width: 100%;
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
  overflow-x: hidden;
  height: 100vh;
  position: relative;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
