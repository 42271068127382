.table_operations {
  .bigcell {
    white-space: normal !important;
  }

  //   .MuiDataGrid-columnHeader:focus {
  //     outline: none;
  //     background-color: red;
  //   }
}
