.wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 1;
  z-index: 10;
  width: 350px;
  overflow: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column-reverse;
}
