.pop_up {
  top: 0;
  left: 0;
  //   background-color: aqua;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);

  .message {
    border-radius: 4px;
    padding: 10px;
    background-color: var(--primary400);
    height: 120px;
    top: 0;
    width: 400px;
    // box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;

    .close {
      margin-bottom: 4px;
      display: flex;
      justify-content: right;
      .icon {
        font-size: 16px;
        cursor: pointer;
      }
    }

    .title {
      text-align: center;
      //   background-color: red;
      flex: auto;
    }

    .wrapper_btn {
      padding-top: 10px;
      //   background-color: bisque;
      display: flex;
      justify-content: center;

      .btn {
        margin: 0 10px;
        width: 70px;
      }
    }
  }
}
