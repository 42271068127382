.btn {
  background-color: transparent;
  border: none;
  color: var(--greenAccent400);
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-bottom: dashed 1px;
  display: inline-block;
}
