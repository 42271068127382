.pop_up {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);

  .message {
    border-radius: 4px;
    padding: 20px;
    background-color: var(--primary400);
    top: 0;
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;

    min-height: 100px;
    min-width: 100px;

    .close {
      margin-bottom: 4px;
      display: flex;
      justify-content: right;
      .icon {
        font-size: 16px;
        cursor: pointer;
      }
    }

    .title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      //   background-color: red;
      // flex: auto;
    }

    .content {
      // padding-top: 10px;
      // background-color: bisque;
      display: flex;
      justify-content: center;
    }
  }
}
