.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .notice {
    margin-top: 20px;
    padding: 6px 10px;
    border: 2px sandybrown solid;
    border-radius: 5px;
  }
}

.wrapper_mixed_payment {
  margin-top: 10px;

  .add_mixed_payment {
    margin-top: 10px;
  }
}

.mixed_payment {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 14px;
  border: 2px var(--greenAccent500) solid;
  border-radius: 5px;

  .btn_delete {
    margin-top: 6px;
    display: flex;
    justify-content: flex-end;
  }
}
