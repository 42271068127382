.menu {
  .fields {
    display: flex;
    gap: 10px;
    // align-items: center;
    align-items:flex-start;

    .icon_wrapper{
      padding: 2px 0;
      display: flex;
      align-items: center;

      .icon {
        margin-left: 16px;
        cursor: pointer;
      }
    }

  }
}
