.result {
  margin-top: 20px;
  .item {
    display: flex;
    width: 250px;
    margin-top: 5px;
    // background-color: red;
    justify-content: space-between;
    .label {
      // margin-right: 30px;
    }
    .sum {
      font-weight: 600;
    }
  }
}
  

  