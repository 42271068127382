@import "./light.scss";
@import "./dark.scss";
@import "./reset.scss";

a {
  color: inherit;
}

.mt20 {
  margin-top: 20px;
}
