

.cell{   
    // border: #555 solid;  
    padding: 1px 10px;
    &:nth-child(1){
        width: 40px;
    }
    &:nth-child(2){
        flex:1;
    }
    &:nth-child(3){
        width: 140px;
    }

    &:nth-child(4){
        width: 80px;
        // flex:1;
    }
}

